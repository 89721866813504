import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enrollment_id: "",
  scanValue: "",
  temp_json: {},
  temp_json_back: {},
  front_preview: "",
  back_preview: "",
};

export const slice = createSlice({
  name: "enduser",
  initialState,
  reducers: {
    setEnrollmentId: (state, action) => {
      state.enrollment_id = action.payload;
    },
    setEnduserScanValue: (state, action) => {
      console.log(action.payload);
      state.scanValue = action.payload;
    },
    setTempJSON: (state, action) => {
      console.log(action.payload, "Front");
      state.temp_json = action.payload;
    },
    setTempJSONBack: (state, action) => {
      console.log(action.payload, "Back");
      state.temp_json_back = action.payload;
    },
    setFrontPreview: (state, action) => {
      console.log(action.payload, "Front Preview");
      state.front_preview = action.payload;
    },
    setBackPreview: (state, action) => {
      console.log(action.payload, "Back Preview");
      state.back_preview = action.payload;
    },
  },
});

export const {
  setBackPreview,
  setEnrollmentId,
  setEnduserScanValue,
  setFrontPreview,
  setTempJSON,
  setTempJSONBack,
} = slice.actions;

export default slice.reducer;
