import React, { useState } from "react";
import { ErrorMessage, useField } from "formik";
import { FormGroup, IconButton, Paper, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const PasswordTextInput = ({ label, ...props }: any) => {
  const [field] = useField(props);
  const [inputType, setInputType] = useState("password");

  const handleShowPassword = () => {
    setInputType(inputType === "password" ? "text" : "password");
  };

  return (
    <FormGroup>
      <Typography
        variant="body1"
        sx={{ color: props.color ? "#FFFFFF" : "#283C92", marginBottom: "4px" }}
      >
        {label}
      </Typography>
      <Paper
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: 1.5,
          //   border: "1px solid #e3e3e3",
          boxShadow: "none",
          backgroundColor: props.color ? "#FFFFFF" : "rgba(40, 60, 146, 0.11)",
        }}
      >
        <input className="password-textinput" {...props} type={inputType} />
        <IconButton
          sx={{ p: "10px", color: "#283C92" }}
          onClick={handleShowPassword}
        >
          {inputType === "password" ? (
            <VisibilityOutlinedIcon />
          ) : (
            <VisibilityOffOutlinedIcon />
          )}
        </IconButton>
      </Paper>
      <ErrorMessage
        component="div"
        name={field.name}
        className="error-message"
      />
    </FormGroup>
  );
};

export default PasswordTextInput;
