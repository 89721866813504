import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  IconButton,
  Typography,
} from "@mui/material";
import logo from "../../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { unAuthRoutes, unAuthRoutesList } from "../routes";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { api } from "../../api/apis";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { login } from "../../features/auth.slice";
import { setAllRoles } from "../../features/masters.slice";
import { createPromiseToast } from "../../utils/toast";
import TextField from "../../components/TextField";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import JSEncrypt from "jsencrypt";
import PasswordTextInput from "../../components/PasswordTextInput";

function Login() {
  const [rememberMeAuth, setRememberMeAuth] = React.useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [show, setShow] = React.useState(false);

  const publicKey: any = process.env.REACT_APP_PUBLIC_KEY;

  const encryptedPassword = (password: string) => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey);
    return encrypt.encrypt(password);
  };

  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe");

    if (JSON.parse(rememberMe ?? "false")) {
      setRememberMeAuth({
        email: localStorage.getItem("email") ?? "",
        password: localStorage.getItem("password") ?? "",
        rememberMe: JSON.parse(rememberMe ?? "false"),
      });
      setChecked(JSON.parse(rememberMe ?? "false"));
    }
  }, []);

  const handleSubmit = async (values: any, actions: any) => {
    setIsLoading(true);
    const encryptPassword: any = encryptedPassword(values.password);
    console.log(encryptPassword, "65");

    await api
      .login(values.email, encryptPassword)
      .then(({ data }) => {
        const user = data.payload;
        if (data?.result) {
          dispatch(login(user));
          if (checked) {
            localStorage.setItem("email", values.email);
            localStorage.setItem("password", values.password);
            localStorage.setItem("rememberMe", "true");
          }
          api
            .getAllRoles()
            .then((res) => {
              const allRoles = res.data.payload;
              dispatch(setAllRoles(allRoles));
            })
            .catch((err) => console.error(err));
        }
        return {
          success: true,
          message: "Logged in successfully",
        };
      })
      .catch((err) => {
        setIsLoading(false);
        actions.resetForm();
        return {
          success: false,
          message: err?.response?.data?.errors[0] ?? err?.message ?? err ?? "",
        };
      })
      .then((toastData: any) => {
        console.log(toastData);
        createPromiseToast(toastData);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  // const validate = Yup.object().shape({
  //   email: Yup.string()
  //     .test(
  //       "no-blank-spaces",
  //       "Email must not contain blank spaces",
  //       (value) => {
  //         return value === undefined || value.trim() === value;
  //       }
  //     )
  //     .required("Email is required")
  //     .email("Invalid email format"),
  //   // .matches(/[a-z]|| [A-Z]/, "2"),
  //   password: Yup.string()
  //     .test(
  //       "no-blank-spaces",
  //       "Password must not contain blank spaces",
  //       (value) => value?.trim() !== " "
  //     )
  //     .strict(true)
  //     .required("Enter your password")
  //     .min(6, "Password must be at least 6 characters"),
  // });

  const validationSchema = Yup.object({
    email: Yup.string()
      .test(
        "no-blank-spaces",
        "Email must not contain blank spaces",
        (value) => value === undefined || value.trim() === value
      )
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .test(
        "no-blan-spaces",
        "Password must not contain blank spaces",
        (value) => value === undefined || value.trim() === value
      )
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  return (
    <Stack sx={{ flexDirection: { xs: "column", md: "row" } }}>
      <Box
        sx={{
          height: { xs: "auto", md: "100dvh" },
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img className="login-logo" src={logo} width="60%" />
      </Box>
      <Box
        sx={{
          height: { xs: "90dvh", md: "100dvh" },
          display: "flex",
          width: "100%",
          backgroundColor: "#283c92",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            marginX: { md: "8rem", sm: "10rem", xs: "1rem" },
            transition: "all 0.3s",
          }}
        >
          <Formik
            initialValues={{
              email: rememberMeAuth?.email,
              password: rememberMeAuth?.password,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {(formik) => {
              // console.log(formik.values);
              return (
                <Form onSubmit={formik.handleSubmit}>
                  <TextField
                    label="Email"
                    className="textinput"
                    placeholder="demo@email.com"
                    value={formik.values.email}
                    onChange={(e: any) => {
                      formik.setFieldTouched("email", true);
                      formik.setFieldValue("email", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    id="email"
                    name="email"
                    color="true"
                  />
                  <br />
                  {/* <Box> */}
                  {/* <TextField
                    label="Password"
                    className="textinput"
                    placeholder="******"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="password"
                    name="password"
                    type="password"
                    color="true"
                  /> */}
                  {/* <Box my={1}>
                      <Stack>
                        <Typography variant="subtitle1" color="#FFF">
                          Password
                        </Typography>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          sx={{
                            height: "50px",
                            // width: "90%",
                            width: "auto",
                            // backgroundColor: "rgba(40, 60, 146, 0.11)",
                            background: "#fff",
                            borderRadius: "6px",
                          }}
                        >
                          <input
                            className="textinput"
                            placeholder="******"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="password"
                            name="password"
                            type={show ? "text" : "password"}
                            color="true"
                            style={{ flex: 1 }}
                          />
                          <IconButton
                            size="medium"
                            onClick={() => setShow(!show)}
                            sx={{
                              boxShadow: "none",
                              bgcolor: "#fff",
                              textTransform: "capitalize",
                            }}
                            component="label"
                          >
                            {show ? (
                              <VisibilityIcon fontSize="small" />
                            ) : (
                              <VisibilityOffIcon fontSize="small" />
                            )}
                          </IconButton>
                        </Box>
                      </Stack>
                      <span className="error-message">
                        {formik.errors.password &&
                          formik.touched.password &&
                          formik.errors.password}
                      </span>
                    </Box> */}
                  {/* </Box> */}
                  <PasswordTextInput
                    label="Password"
                    placeholder="Enter password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="password"
                    name="password"
                    color="true"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#f2f2f2",
                          backgroundColor: "transparent",
                        }}
                        checked={checked}
                        color={"success"}
                        onChange={handleChange}
                      />
                    }
                    label={<Typography variant="body2">Remember Me</Typography>}
                    sx={{
                      color: "#fff",
                      fontSize: "0.5rem",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      sx={{
                        backgroundColor: "#fff",
                        marginY: "10px",
                        color: "#283c92",
                        textTransform: "capitalize",
                        boxShadow: "none",
                        width: "200px",
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                    >
                      {isLoading === true ? (
                        <CircularProgress
                          size={26}
                          style={{ color: "#283C92" }}
                        />
                      ) : (
                        "Login"
                      )}
                    </Button>
                    <Link to={unAuthRoutes.forgotPassword.paths[0]}>
                      <Typography
                        variant="body2"
                        color="#fff"
                        sx={{ marginY: "10px" }}
                      >
                        Forgot Password ?
                      </Typography>
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </Stack>
  );
}

export default Login;
